<template>
    <div class="gibs-newsletter-form-container">
        <div class="newsletter-form-wrapper">
            <form>
                <div class="gibs-signup-wrapper">
                    <div class="gibs-signup-containers">
                        <div class="gibs-newsletter-form-elements-container">
                            <p class="gibs-share-prod-form-title" v-if="!subProgOpen">Closed: Call me to discuss</p>
                            <p class="gibs-share-prod-form-title" v-else>Call me to discuss</p>
                            <form @submit.prevent="submitForm" class="form-container" id="closed-application-form-id">
                                <hr class="gibs-divider mb-[17px]" />
                                <div class="gibs-share-prod-form-subtitle">{{ applicationClosedStore.getSubProgrammeName }}
                                </div>
                                <p class="gibs-closed-application-form-message"
                                    v-if="props.loggedinName && props.isLoggedIn">Hi {{ props.loggedinName }},
                                    please complete the form below and GIBS will contact you in regards to your enquiry
                                </p>
                                <p class="mb-[17px]" v-if="!props.loggedinName || !props.isLoggedIn"></p>
                                <hr class="gibs-divider mb-[17px]" />
                                <GibsControlFormInput v-if="!props.loggedinName || !props.isLoggedIn" type="text"
                                    inputId="application-closed-form-first-name" labelText="First name"
                                    placeholderText="First name" v-model="form.Name" @input="nameInput"
                                    :validator="validation" :isRequired="true"
                                    :validator-rule-id="useNuxtApp().$EnumApplicationClosedFormValidatorId.Name">
                                </GibsControlFormInput>
                                <GibsControlFormInput v-if="!props.loggedinName || !props.isLoggedIn" type="text"
                                    inputId="application-closed-surname" labelText="Last name" placeholderText="Last name"
                                    v-model="form.LastName" @input="surnameInput" :isRequired="true" :validator="validation"
                                    :validator-rule-id="useNuxtApp().$EnumApplicationClosedFormValidatorId.Surname">
                                </GibsControlFormInput>
                                <GibsControlFormInput type="email" inputId="application-closed-email" labelText="Email"
                                    class="mb-4" placeholderText="Email address" v-model="form.Email" :is-required="true"
                                    :validator="validation"
                                    :validator-rule-id="useNuxtApp().$EnumApplicationClosedFormValidatorId.Email"
                                    @input="emailInput">
                                </GibsControlFormInput>
                                <GibsControlFormInput type="tel" inputId="application-closed-number"
                                    labelText="Contact number" placeholderText="Format: +27821234567"
                                    v-model="form.ContactNumber" :isRequired="true" :validator="validation"
                                    :validator-rule-id="useNuxtApp().$EnumApplicationClosedFormValidatorId.ContactNumber"
                                    @input="contactInput">
                                </GibsControlFormInput>
                                <GibsControlFormTextarea inputId="application-closed-message" labelText="Message"
                                    placeholderText="How may we assist you?" v-model="form.Message" :isRequired="false"
                                    :validator="validation"
                                    :validator-rule-id="useNuxtApp().$EnumApplicationClosedFormValidatorId.Message"
                                    @input="messageInput">
                                </GibsControlFormTextarea>
                                <GibsControlFormCheckbox type="checkbox" inputId="application-closed-alert" v-if="!subProgOpen"
                                    labelText="Alert me when application is open" v-model="form.AlertMe"
                                    @input="alertCheckboxChange">
                                </GibsControlFormCheckbox>
                                <div class="mt-4.5 max-w-full xs:w-full xs:max-w-full">
                                    <GibsControlPrimaryButton :primaryButtonSpec="shareProgrammeBtn" type="submit"
                                        @click="submitForm" />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template> 

<script setup lang="ts">
import { debounce } from 'lodash';
import { useNotificationStore } from "~/stores/notification";
import { ServerValidationError } from "~/classes/validation-errors";
import { useProgApplicationClosedStore } from '~/stores/application-closed';
import { GibsUserSession } from '~/schemas/gibs-user-session';

const props = defineProps({
    "labelText": {
        type: String
    },
    "layoutView": {
        type: String
    },
    "loggedinName": {
        type: String
    },
    "loggedinSurname": {
        type: String
    },
    "isLoggedIn": {
        type: Boolean
    }
});

const store = useNotificationStore();
const app = useNuxtApp();
const applicationClosedStore = useProgApplicationClosedStore();
const subProgOpen = ref(false); 

const emit = defineEmits(["closeModal"]);

const form = ref<IApplicationClosedForm>({
    Email: "",
    ContactNumber: "",
    Message: "",
    AlertMe: false,
    Name: "",
    LastName: "",
    ProgrammeID: ""
});

const { applicationClosedShareFormValidation: applicationClosedShareFormValidation } = useValidations();
const validation = applicationClosedShareFormValidation(form.value);

const shareProgrammeBtn = ref<IControlButton>({
    buttonElName: "gibs-primary-button",
    buttonText: "Submit",
    class: "btn-create-profile gibs-btn-share-closed-application",
    sourceUrl: "",
    load: false
});
shareProgrammeBtn.value.disabled = !validation.isValid();

function nameInput(input: string) {
    form.value.Name = input;
    validation.setValidationRuleSourceValue(app.$EnumApplicationClosedFormValidatorId.Name, input);
    shareProgrammeBtn.value.disabled = !validation.isValid();
}

function surnameInput(input: string) {
    form.value.LastName = input;
    validation.setValidationRuleSourceValue(app.$EnumApplicationClosedFormValidatorId.Surname, input);
    shareProgrammeBtn.value.disabled = !validation.isValid();
}

const emailInput =  (input: string) => {
    form.value.Email = input;

    form.value.Email = input;
    validation.setValidationRuleSourceValue(app.$EnumApplicationClosedFormValidatorId.Email, input);
    shareProgrammeBtn.value.disabled = !validation.isValid();
};

function contactInput(input: string) {
    form.value.ContactNumber = input;
    validation.setValidationRuleSourceValue(app.$EnumApplicationClosedFormValidatorId.ContactNumber, input);
    shareProgrammeBtn.value.disabled = !validation.isValid();
}

function messageInput(input: string) {
    form.value.Message = input;
    validation.setValidationRuleSourceValue(app.$EnumApplicationClosedFormValidatorId.Message, input);
    shareProgrammeBtn.value.disabled = !validation.isValid();
}

function alertCheckboxChange(input: boolean) {
    form.value.AlertMe = input;
    validation.setValidationRuleSourceValue(app.$EnumApplicationClosedFormValidatorId.AlertMe, input);
    //shareProgrammeBtn.value.disabled = !validation.isValid();
}

function submitForm() {
    try { 
        const formData: IApplicationClosedForm = {
            ProgrammeID: applicationClosedStore.getSubProgrammeId,
            Email: form.value.Email,
            Name: form.value.Name,
            LastName: form.value.LastName,
            ContactNumber: form.value.ContactNumber,
            Message: form.value.Message,
            AlertMe: form.value.AlertMe
        };

        sendDataToServer(formData);
    }
    catch (error) {
        let bodyText;

        if (error instanceof Error) {
            bodyText = error.message;
        }
        else {
            bodyText = '';
        }

        store.addNotification({
            type: "error",
            title: `There was an error while sharing the programme. Please try again.`,
            body: bodyText,
            date: new Date(),
            timeout: true,
            timer: 10000
        });
    }
}

const sendDataToServer = async (formData: IApplicationClosedForm) => {
    let notification: INotification;

    try {
        shareProgrammeBtn.value.disabled = true;
        shareProgrammeBtn.value.load = true;

        const { data: response, error, status } = await useLoaderFetch("/api/applicationclosedshare/postFormData", {
            method: "POST",
            body: formData
        });

        shareProgrammeBtn.value.disabled = false;
        shareProgrammeBtn.value.load = false;

        if (response != null) {
            
            const isSuccess: string = "success";
            const isError: string = "error";

            if (status.value === isSuccess) {
                //let shareResponse = SubscriptionResponse.parse(response.value);
                var successMessage = "The programme manager will contact you to discuss " + applicationClosedStore.getSubProgrammeName;
                //if (shareResponse.success == true) {
                    notification = {
                        type: "success",
                        title: successMessage,
                        body: "",
                        date: new Date(),
                        timeout: true,
                        timer: 4000,
                    };
                    // Reset the form after successful submission
                    form.value.Email = "";
                    form.value.Name = "";
                    form.value.LastName = "";
                    form.value.Message = "";
                    form.value.ContactNumber = "";
                    form.value.ProgrammeID = "";
                    form.value.AlertMe = false;

                    validation.setValidationRuleSourceValue(app.$EnumApplicationClosedFormValidatorId.Email, form.value.Email);
                    validation.setValidationRuleSourceValue(app.$EnumApplicationClosedFormValidatorId.Name, form.value.Name);
                    validation.setValidationRuleSourceValue(app.$EnumApplicationClosedFormValidatorId.Surname, form.value.LastName);
                    validation.setValidationRuleSourceValue(app.$EnumApplicationClosedFormValidatorId.Message, form.value.Message);
                    validation.setValidationRuleSourceValue(app.$EnumApplicationClosedFormValidatorId.ContactNumber, form.value.ContactNumber);

                    shareProgrammeBtn.value.disabled = !validation.isValid();
                    emit("closeModal");
                    store.addNotification(notification);
            } else if (status.value === isError) {
                notification = {
                    type: "error",
                    title: "",
                    body: `There was an error while sharing the programme. Please try again.`,
                    date: new Date(),
                    timeout: true,
                    timer: 10000
                };
                store.addNotification(notification);
            }
        }

    } catch (error) {
        let bodyText;

        if (error instanceof ServerValidationError) {
            bodyText = error.message;
        } else {
            bodyText = (error as Error).message;
        }

        notification = {
            type: "error",
            title: "occurred.",
            body: bodyText,
            date: new Date(),
            timeout: true,
            timer: 10000
        };
        store.addNotification(notification);
    }
};  

let isClosedFormModalOpen = ref(false); 

watch(() => applicationClosedStore.getApplicationClosedModal, async (newVal) => {
    isClosedFormModalOpen.value = newVal; 

    if (!isClosedFormModalOpen.value) {
        form.value.Email = "";
        form.value.Name = "";
        form.value.LastName = "";
        form.value.Message = "";
        form.value.ContactNumber = "";
        form.value.ProgrammeID = "";
        form.value.AlertMe = false;

        validation.setValidationRuleSourceValue(app.$EnumApplicationClosedFormValidatorId.Email, form.value.Email);
        validation.setValidationRuleSourceValue(app.$EnumApplicationClosedFormValidatorId.Name, form.value.Name);
        validation.setValidationRuleSourceValue(app.$EnumApplicationClosedFormValidatorId.Surname, form.value.LastName);
        validation.setValidationRuleSourceValue(app.$EnumApplicationClosedFormValidatorId.Message, form.value.Message);
        validation.setValidationRuleSourceValue(app.$EnumApplicationClosedFormValidatorId.ContactNumber, form.value.ContactNumber);

        var formId = document.getElementById('closed-application-form-id') as HTMLFormElement
        if (formId != null) {
            formId.reset();
        }
        
        shareProgrammeBtn.value.disabled = !validation.isValid();
    }
    else {
        await authCheck();
         // name and surname values will be set for logged in users by the server side call "/api/applicationclosedshare/postFormData"
        if (isLoggedIn.value) {
            let name = loggedInProfileName.value;
            if (props.loggedinName) {
                name = props.loggedinName;
            }
            form.value.Name = name;
            validation.setValidationRuleSourceValue(app.$EnumApplicationClosedFormValidatorId.Name, name);
            shareProgrammeBtn.value.disabled = !validation.isValid(); 
        }  

        if (isLoggedIn.value) {
            let surname = props.loggedinName;  
            surname = loggedInProfileSurname.value; 
            form.value.LastName = surname;
            validation.setValidationRuleSourceValue(app.$EnumApplicationClosedFormValidatorId.Surname, surname);
            shareProgrammeBtn.value.disabled = !validation.isValid();
        }
    }
});

watch(() => applicationClosedStore.getSubProgrammeState, (newVal) => {
    subProgOpen.value = newVal;  
});

let isLoggedIn = ref(false);
let isLoggedInStored = ref('false');
let loggedInProfileName = ref(""); 
let loggedInProfileSurname = ref("");

isLoggedInStored.value = process.client ? localStorage.getItem('Is_Logged_In') ?? 'false' : 'false';
if (isLoggedInStored.value == 'true') {
    isLoggedIn.value = true;
}
else if (isLoggedInStored.value == 'false') {
    isLoggedIn.value = false;
}
loggedInProfileName.value = process.client ? localStorage.getItem('Logged_In_Name') ?? '' : '';
loggedInProfileSurname.value = process.client ? localStorage.getItem('Logged_In_Surname') ?? '' : '';

const authCheck = async () => {
    const { data: profile, error: authError, status: authStatus } = await useFetch('/api/auth/user');
    if (profile != null) 
    {
        const isSuccess: string = 'success';
        const isError: string = 'error';
        
        if (authStatus.value === isSuccess) {
            const parsedData = GibsUserSession.safeParse(profile.value);
            if (parsedData.success) {
                isLoggedIn.value = parsedData.data.isLoggedIn;
                if (parsedData.data.isLoggedIn) {
                    // These values will be set for logged in users by the server side call "/api/applicationclosedshare/postFormData"
                    loggedInProfileName.value = parsedData.data.userName;
                    loggedInProfileSurname.value = (typeof(parsedData.data.lastName) !== 'undefined' && parsedData.data.lastName != null) ? parsedData.data.lastName: '';
                }
            }
        }
    }
};

onMounted(async () => {
    await authCheck();
});
</script>   