<template>
    <div class="gibs-modal-form-container">
        <div class="gibs-modal-confirmation-wrapper">
            <div class="gibs-confirmation-popup">
                <div class="gibs-popup-content">
                    <div class="gibs-modal-confirmation-heading">{{ $props.buttonText }}</div>
                    <hr class="gibs-divider mt-3.75 mb-5.5" />
                    <p class="gibs-popup-text">{{ $props.confirmationText }}</p> 
                    <div class="gibs-modal-confirmation-buttons-container">
                        <GibsControlPrimaryButton :primaryButtonSpec="cancelProgrammeBtn" loaderClass="confirmation-button-spinner-container" type="submit" @click="confirmAction"/>
                        <GibsControlPrimaryButton :primaryButtonSpec="doNotCancelProgrammeBtn" type="submit" @click="cancelAction"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
 
<script setup lang="ts">
import { useAbortEnrolmentStore } from '~/stores/abort-enrolment';

const abortEnrolmentStore = useAbortEnrolmentStore();
const emit = defineEmits(["closeModal"]);
const showLoader = ref(false);

const props = defineProps({
        buttonText: {
            type: String,
            required: false,
            default: 'Confirm cancellation'
        },
        confirmationText: {
            type: String,
            required: false,
            default: 'Are you sure you want to cancel the application?'
        } 
    });

const cancelProgrammeBtn = ref<IControlButton>({
    buttonElName: "gibs-enrolment-confirmation-popup-abort-button",
    buttonText: "Yes",
    class: "btn-cancel-enrolment w-full xs:max-w-[132px] xs:w-full",
    sourceUrl: "",
    load: false
});

const doNotCancelProgrammeBtn = ref<IControlButton>({
    buttonElName: "gibs-enrolment-confirmation-popup-cancel-button",
    buttonText: "No",
    class: "btn-do-not-cancel-enrolment w-full xs:max-w-[132px] xs:w-full",
    sourceUrl: "",
    load: false
});

function confirmAction() {
    abortEnrolmentStore.setAbortBtnClicked(true);
    abortEnrolmentStore.setCancelApplicationLoader(true);
    cancelProgrammeBtn.value.load = false;
}

function cancelAction() {
    abortEnrolmentStore.setAbortBtnClicked(false); 
    abortEnrolmentStore.setCancelApplicationLoader(false);
    emit("closeModal");
} 

if (showLoader.value) {
        cancelProgrammeBtn.value.load = true;
    }
    else {
        cancelProgrammeBtn.value.load = false;
    }

watch(() => abortEnrolmentStore.getCancelApplicationLoader, (newVal) => { 
    showLoader.value = newVal;
    if (newVal) {
        cancelProgrammeBtn.value.load = true;
    }
    else {
        cancelProgrammeBtn.value.load = false;
    }
});
</script>   