<template>
    <div class="gibs-newsletter-form-container">
        <div class="newsletter-form-wrapper">
            <form @submit.prevent="submitForm">
                <div class="gibs-signup-wrapper">
                    <div class="gibs-signup-containers">
                        <div class="gibs-newsletter-form-elements-container">
                            <p class="gibs-newsletter-form-title">Subscribe to our Newsletter</p>
                            <form @submit.prevent="submitForm" class="form-container">
                                <GibsControlFormInput type="email" inputId="newsletter-email" labelText="Email address" class="mb-4"
                                    placeholderText="Email address" v-model="form.email" :is-required="true"
                                    :validator="validation"
                                    :validator-rule-id="useNuxtApp().$EnumNewsletterSubscriptionFormValidatorId.SignupEmail"
                                    @input="emailInput">
                                </GibsControlFormInput>
                                <hr class="gibs-divider mb-[17px]" />
                                <GibsControlFormInput type="text" inputId="newsletter-firstName" labelText="First name" 
                                    placeholderText="First name" v-model="form.name" @input="firstNameInput" :is-required="true"
                                    :validator="validation"
                                    :validator-rule-id="useNuxtApp().$EnumNewsletterSubscriptionFormValidatorId.Name">
                                </GibsControlFormInput>
                                <GibsControlFormInput type="text" inputId="newsletter-surname" labelText="Surname"
                                    placeholderText="Surname" v-model="form.surname" @input="surnameInput" :is-required="true"
                                    :validator="validation"
                                    :validator-rule-id="useNuxtApp().$EnumNewsletterSubscriptionFormValidatorId.Surname">
                                </GibsControlFormInput>
                                <hr class="gibs-divider mt-4 mb-4" />
                                <div class="newsletter-form-footer-text">
                                    <p>
                                        The GIBS newsletter can be customised to your Interest Areas. If you would
                                        like to customise the newsletter please
                                        <template v-if="props.profileLoggedIn === true">
                                            <NuxtLink class="form-hyperlink" @click="displayLoggedInNotification">create a profile</NuxtLink>
                                        </template>
                                        <template v-else>
                                            <NuxtLink class="form-hyperlink" to="/account/sign-up" @click="emit('closeModal', false)">create a profile</NuxtLink>
                                        </template>
                                        and choose the topics you are interested in.
                                    </p>
                                    <div class="vertical-spacing"></div>
                                    <p>
                                        To continue to receive the general newsletter please select Submit below.
                                    </p>
                                </div>
                                <div class="mt-4.5 max-w-full xs:w-full xs:max-w-full" >
                                    <GibsControlPrimaryButton :primaryButtonSpec="subscribeToNewsletterBtn" type="submit" />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template> 
 
<script setup lang="ts">
import { useNotificationStore } from "~/stores/notification";
import { ServerValidationError } from "~/classes/validation-errors";

const props = defineProps({
    "profileLoggedIn": {
        type: Boolean,
        default: false
    },
    "labelText": {
        type: String
    },
    "layoutView": {
        type: String
    }
    // "isModalOpen": {
    //     type: Boolean,
    //     default: false
    // }
});

const store = useNotificationStore();
const app = useNuxtApp();
const config = useRuntimeConfig();
const { vueApp } = useNuxtApp();

const emit = defineEmits(["closeModal"]); 

const form = ref<INewsletterSubscription>({
    email: "",
    name: "",
    surname: ""
});

const { newsletterSubscriptionFormValidation: newsletterFormValidation } = useValidations();
const validation = newsletterFormValidation(form.value);

const createProfileButton: IControlButton = {
    buttonElName: "gibs-create-profile-link",
    buttonText: "create a profile",
    class: "gibs-create-profile-link",
    enableUrl: false,
    externalSource: false,
    sourceUrl: "",
}

const subscribeToNewsletterBtn = ref<IControlButton>({
    buttonElName: "gibs-primary-button",
    buttonText: "Submit",
    class: "btn-create-profile w-full xs:max-w-[132px] xs:w-full",
    sourceUrl: "",
    load: false
});
subscribeToNewsletterBtn.value.disabled = !validation.isValid();

function emailInput(input: string) {
    form.value.email = input;
    validation.setValidationRuleSourceValue(app.$EnumNewsletterSubscriptionFormValidatorId.SignupEmail, input);
    subscribeToNewsletterBtn.value.disabled = !validation.isValid();
}

function firstNameInput(input: string) {
    form.value.name = input;
    validation.setValidationRuleSourceValue(app.$EnumNewsletterSubscriptionFormValidatorId.Name, input);
    subscribeToNewsletterBtn.value.disabled = !validation.isValid();
}

function surnameInput(input: string) {
    form.value.surname = input;
    validation.setValidationRuleSourceValue(app.$EnumNewsletterSubscriptionFormValidatorId.Surname, input);
    subscribeToNewsletterBtn.value.disabled = !validation.isValid();
}

function displayLoggedInNotification() {
    let notification = {
        type: "note",
        title: "You are already logged in.",
        body: ``,
        date: new Date(),
        timeout: true
    };
    store.addNotification(notification);
}

const submitForm = async () => {
    try {
        const formData: INewsletterSubscription = {
            email: form.value.email,
            name: form.value.name,
            surname: form.value.surname
        };

        sendDataToServer(formData);
    }
    catch (error) {
        let bodyText;

        if (error instanceof Error) {
            bodyText = error.message;
        }
        else {
            bodyText = '';
        }

        store.addNotification({
            type: "error",
            title: `There was an error while subscribing you to the newsletter. Please try again.`,
            body: bodyText,
            date: new Date(),
            timeout: false,
            timer: 5000
        });
    }
};

const sendDataToServer = async (formData: INewsletterSubscription) => {
    let notification: INotification;

    try {
        subscribeToNewsletterBtn.value.disabled = true;
        subscribeToNewsletterBtn.value.load = true;

        const { data: response, error, status } = await useLoaderFetch("/api/newsletterform/postFormData", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: {
                data: formData
            },
        });

        subscribeToNewsletterBtn.value.disabled = false;
        subscribeToNewsletterBtn.value.load = false;

        const isSuccess: string = "success";
        const isError: string = "error";

        if (status.value === isSuccess) {
            let subscriptionResponse = SubscriptionResponse.parse(response.value);

            if (subscriptionResponse.success == true) {
                notification = {
                    type: "success",
                    title: "Form submitted successfully.",
                    body: "",
                    date: new Date(),
                    timeout: true,
                    timer: 10000,
                };
                // Reset the form after successful submission
                form.value.email = "";
                form.value.name = "";
                form.value.surname = "";

                validation.setValidationRuleSourceValue(app.$EnumNewsletterSubscriptionFormValidatorId.SignupEmail, form.value.email);
                validation.setValidationRuleSourceValue(app.$EnumNewsletterSubscriptionFormValidatorId.Name, form.value.name);
                validation.setValidationRuleSourceValue(app.$EnumNewsletterSubscriptionFormValidatorId.Surname, form.value.surname);

                subscribeToNewsletterBtn.value.disabled = !validation.isValid();
                emit("closeModal");
                store.addNotification(notification); 
            } else if (subscriptionResponse.success == false) {
                notification = {
                    type: "error",
                    title: "",
                    body: subscriptionResponse.error!,
                    date: new Date(),
                    timeout: true,
                    timer: 10000
                };
                store.addNotification(notification);
            }
        } else if (status.value === isError) {
            notification = {
                type: "error",
                title: "",
                body: `There was an error while subscribing you to the newsletter. Please try again.`,
                date: new Date(),
                timeout: true,
                timer: 10000
            };
            store.addNotification(notification);
        }
    } catch (error) {
        let bodyText;

        if (error instanceof ServerValidationError) {
            bodyText = error.message;
        } else {
            bodyText = (error as Error).message;
        }

        notification = {
            type: "error",
            title: "",
            body: bodyText,
            date: new Date(),
            timeout: true,
            timer: 10000
        };
        store.addNotification(notification);
    }
}; 
</script>   