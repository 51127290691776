import { z } from 'zod';

export const SubscriptionResponse = z.object({
    success: z.boolean().optional(),
    error: z.string().nullable().optional(),
    errorId: z.number().optional(),
    userID: z.string().optional(),
    jwtToken:z.string().nullable().optional()
  });

