<template>
    <div class="gibs-modal-form-container">
        <div class="gibs-modal-confirmation-wrapper">
            <div class="gibs-confirmation-popup">
                <div class="gibs-popup-content">
                    <div class="gibs-modal-confirmation-heading">{{ $props.buttonText }}</div>
                    <hr class="gibs-divider mt-3.75 mb-5.5" />
                    <p class="gibs-popup-text">{{ $props.confirmationText }}</p> 
                    <div class="gibs-modal-confirmation-buttons-container">
                        <GibsControlPrimaryButton :primaryButtonSpec="redirectToPaygateBtn" loaderClass="confirmation-payment-button-spinner-container" type="submit" @click="confirmAction"/>
                        <GibsControlPrimaryButton :primaryButtonSpec="doNotRedirectBtn" type="submit" @click="cancelAction"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
 
<script setup lang="ts">
import { useFormOnlinePaymentStore } from '~/stores/online-payment';

const formPayOnlineStore = useFormOnlinePaymentStore();
const emit = defineEmits(["closeModal"]);
const showLoader = ref(false);

const props = defineProps({
        buttonText: {
            type: String,
            required: true,
            default: 'Confirm cancellation'
        },
        confirmationText: {
            type: String,
            required: true,
            default: 'Are you sure you want to abort the application?'
        } 
    });

const redirectToPaygateBtn = ref<IControlButton>({
    buttonElName: "gibs-pay-online-button",
    buttonText: "Yes",
    class: "btn-cancel-enrolment w-full xs:max-w-[132px] xs:w-full",
    sourceUrl: "",
    load: false
});

const doNotRedirectBtn = ref<IControlButton>({
    buttonElName: "gibs-do-not-redirect-to-paygate-button",
    buttonText: "No",
    class: "btn-do-not-cancel-enrolment w-full xs:max-w-[132px] xs:w-full",
    sourceUrl: "",
    load: false
});

function confirmAction() {
    formPayOnlineStore.setContinueBtnClicked(true);
    //formPayOnlineStore.setRedirectToPaymentLoader(true);
    redirectToPaygateBtn.value.load = true;
}

function cancelAction() {
    formPayOnlineStore.setContinueBtnClicked(false); 
    //formPayOnlineStore.setRedirectToPaymentLoader(false);
    emit("closeModal");
} 

// if (showLoader.value) {
//         redirectToPaygateBtn.value.load = true;
//     }
//     else {
//         redirectToPaygateBtn.value.load = false;
//     }

watch(() => formPayOnlineStore.getRedirectToPaymentLoader, (newVal) => { 
    showLoader.value = newVal;
    if (newVal) {
        redirectToPaygateBtn.value.load = true;
    }
    else {
        redirectToPaygateBtn.value.load = false;
    }
});

watch(() => formPayOnlineStore.getContinueBtnClicked, (newVal) => {
    showLoader.value = newVal;
    if (newVal) {
        redirectToPaygateBtn.value.load = true;
    }
    else {
        redirectToPaygateBtn.value.load = false;
    }
}); 
</script>   