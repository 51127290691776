import { z } from 'zod';

const SecondaryNavigation = z.object({
    Name: z.string(),
    URL: z.string(),
    Target: z.string(),
    expanded: z.boolean().optional(),
});

const SecondaryNavigationQuery = z.array(z.object({
    Name: z.string(),
    URL: z.string(),
    Target: z.string(),
    Items: z.union([z.array(SecondaryNavigation), z.null()]),
    expanded: z.boolean().optional(),
}));

export { SecondaryNavigationQuery, SecondaryNavigation };