<template v-if="layoutView.view !== 'mobile'">
	<div class="gibs-login-wrapper">
		<div class="gibs-login-containers">
			<div id="gibs-login-form" class="gibs-login-form">
				<h3 class="forgot-password-modal-title">Password reset email has been sent</h3>
				<hr class="gibs-divider mt-3.75">
				<p class="forgot-password-description">If a registered account was found, an email will be sent with a link to reset the password linked to your GIBS account</p>
				<hr class="gibs-divider mb-5.5"> 
				<GibsControlPrimaryButton :primaryButtonSpec="forgotFormSubmitButton" @click="toggleBackAndClose()"/> 
			</div>
		</div>
	</div> 
</template>

<script setup lang="ts">

const fpStore = useForgotPasswordStore();
const forgotFormSubmitButton = ref<IControlButton>({
	buttonElName: `close-modal-button`,
	buttonText: "Close",
	class: "submit-contact-form"  
}); 

const showModal = ref<boolean>(true);

const emit = defineEmits(['closeModalAndShowLogin']);

function toggleBackAndClose() {
	fpStore.isClosedForgotPassword();
	showModal.value = false;
	emit('closeModalAndShowLogin', showModal.value);
	showModal.value = true;
}
 
</script>