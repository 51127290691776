<template>
    <NuxtLink v-if="props.courseFinderButtonSpec.enableUrl" 
        :id="props.courseFinderButtonSpec.buttonElName" 
        class="gibs-course-finder-button" :class="props.courseFinderButtonSpec.class" 
        :external="props.courseFinderButtonSpec.externalSource" 
        :to="props.courseFinderButtonSpec.sourceUrl"
        :target="openLinkInNewTab()"
    >
        <span>{{ props.courseFinderButtonSpec.buttonText }}</span>
    </NuxtLink>
</template>
  
<script setup lang="ts">

    const props = defineProps({
        courseFinderButtonSpec: {
            type: Object,
            default: buttonObject
        }
    });

    function openLinkInNewTab():string {
        return props.courseFinderButtonSpec.openNewTab? '_blank':'';
    }

</script>

<script lang="ts">
    var buttonObject:IControlButton = {
        buttonElName: "course-finder-button",
        buttonText: "Course Finder",
        class: "",
        enableUrl: true,
        externalSource: false,
        openNewTab: false,
        sourceUrl: "/"
    }
</script>