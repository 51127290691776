import { defineStore } from "pinia";

export const useActiveMenuStateStore = defineStore("activeMenuStateStore", () => {
    const isMenuItemManuallySet = ref<boolean>(false);
    
    const getIsMenuItemManuallySet = computed(
        () => {
            return isMenuItemManuallySet.value;
        }
    );

    function setIsMenuItemManuallySet(isSet: boolean) {
        isMenuItemManuallySet.value = isSet;
    }

    return {
        setIsMenuItemManuallySet,
        getIsMenuItemManuallySet
    }
});