<template>
    <div :id="modalId" tabindex="-1" aria-hidden="true" class="gibs-modal-container">
      <div :id="`${modalId}-wrapper-box`" class="gibs-modal-wrapper-box">
          <div id="gibs-close-modal" class="gibs-close-modal-btn-container">
            <button class="gibs-close-modal-container" @click="closeModal">
              <nuxt-icon class="login-modal-close-btn" name="buttons/close-cross" />
            </button>
          </div>
          <slot :key="componentKey" name="modal-content"></slot>
        </div>
    </div>
  </template>
  
  <script setup lang="ts">
  import { Modal } from 'flowbite';
  import { type ModalInterface, type ModalOptions  } from 'flowbite';

  const emit = defineEmits(["closeModal"]);
  const componentKey = ref<number>(0);
  
  const props = defineProps({
    modalId: {
      Type: String,
      default: ""
    },
    isModalOpen: {
      Type: Boolean,
      default: false
    }
  });
  
  const modal = ref<ModalInterface | null>(null);
    const backdropEl = ref();

  function setupModal() {
    const $modalElement: HTMLElement | null = document.getElementById(props.modalId);

  
    const modalOptions: ModalOptions = {
      placement: 'center-center',
      backdrop: 'dynamic',
      backdropClasses: 'gibs-backdrop-background bg-gray-900 bg-opacity-80 dark:bg-opacity-90 fixed inset-0 z-10',
      closable: true,
      onHide: () => {
        ////console.log('modal is hidden');
        emit("closeModal", false);
      },
      onShow: () => {
        ////console.log('modal is shown');
      },
      onToggle: () => {
        ////console.log('modal has been toggled');
      },
    };

    modal.value = new Modal($modalElement, modalOptions);
  }
  
  function closeModal() {
    if (modal.value) {
      modal.value.hide();
      emit("closeModal", false);
    }
  }

  function scrollToTop() {
    const targetElement = document.querySelector("#gibs-add-form-modal-wrapper-box");
    if (targetElement) {
      targetElement.scrollIntoView({block: "start"});
    }
  }
  
  watch(
    () => props.isModalOpen,
    (newValue) => {
      
      setTimeout(() => {
        scrollToTop()
        if (newValue) {
          if (modal.value) {
            modal.value.show();
            backdropEl.value = document.querySelector(".gibs-backdrop-background");
          }
          // makes sure that the form/object in the modal is within the browser's screen. If not, set align items to start so it's scrollable 
          const modalContainerElement = document.getElementById(props.modalId);
          const modalFormElement = document.getElementById(`${props.modalId}-wrapper-box`);
          const browserHeight = window.innerHeight;
          if (modalContainerElement && modalFormElement) {
            const modalFormHeight = modalFormElement.scrollHeight;
            if (modalFormHeight > browserHeight) {
              modalContainerElement.classList.add("!items-start");
            } else {
              modalContainerElement.classList.remove("!items-start");
            }
          }
        } else {
          const modalContainerElement = document.getElementById(props.modalId);
          if (modalContainerElement) {
            modalContainerElement.classList.remove("!items-start");
          }
          closeModal();
        }
      }, 500)
    }
  );
  
  onMounted(() => {
    setupModal();
    if (props.isModalOpen) {
      if (modal.value) {
        modal.value.show();
      }
    }

    const fullModalEl = document.getElementById(`${props.modalId}`);
    const modalEl = document.getElementById(`${props.modalId}-wrapper-box`);
    
    function setStaticBackDrop() {
      fullModalEl?.classList.add('pointer-events-none');
      backdropEl.value = document.querySelector(".gibs-backdrop-background");
      backdropEl.value!.addEventListener("mouseup", setDynamicBackDrop);
      
    }

    function setDynamicBackDrop() {
      fullModalEl?.classList.remove('pointer-events-none');
    }

    fullModalEl!.addEventListener("mousedown", e => {
      modalEl!.addEventListener("mouseleave", setStaticBackDrop);
    });

    fullModalEl!.addEventListener("mouseup", e => {
      setDynamicBackDrop();
      modalEl!.removeEventListener("mouseleave",setStaticBackDrop);
    });
  });
  </script>
  