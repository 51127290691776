<template>
    <div class="gibs-content-form-inputs">

        <template v-if="formFields.length > 0">
            <GibsFormsContentBase ref="baseContentRef" 
                        :Title="modalDescription" 
                        :FormFields="formFields"
                        v-model="formSection"
                        :SubmitVisible="false"
                        :SaveAndContinueVisible="false"
                        :IsModal="true"
                        @formFieldValueChange="formFieldValueChange"
                        :CustomValidation="customValidation"
                        @validateAllSections="validateAllSections"
                        :key="uniqueKey"
                        :showControl="showControl">
                    <template v-slot:footerButtons v-if="isFormSectionDynamicEval()">
                            <GibsControlPrimaryButton v-if="displayAddButton"
                                :disabled="IsButtonDisabled" 
                                :primaryButtonSpec="addObjectToFormButton"
                                @click="addObjectToForm">
                            </GibsControlPrimaryButton>
                            <GibsControlPrimaryButton v-else 
                                :primaryButtonSpec="updateObjectFormButton" 
                                :disabled="IsButtonDisabled" 
                                @click="updateObjectForm">
                            </GibsControlPrimaryButton>
                    </template>
            </GibsFormsContentBase>
            <!-- <input type="text" :value="getJsonFormSection()"/> -->
        </template>
    </div>
</template>

<script setup lang="ts">
    import { z } from 'zod';
    import { FieldInfo } from '~/schemas/field-info';
    import { getUniqueKey } from '~/utils/display-utils';
    import { Validation } from '~/classes/validation';

    const showControl = ref<boolean>(true);
    const emit = defineEmits([
        'saveAndContinue',
        'nextSection',
        'formDropDownChange',
        'formTextInput',
        'formLookupChange',
        'formBooleanChange', 
        "subscribeToParentForm", 
        "formDateTimeChange", 
        "validateAllSections",
        "formFieldValueChange",
    ]);

    const { dynamicWebFormValidation } = useValidations();
    const addFormStore = useAddFormStore(); 
    const formStore = useFormsStore();
    const store = useNotificationStore();

    const formFields= ref<z.infer<typeof FieldInfo>>([]);
    const displayAddButton = ref<boolean>(false);
    const IsButtonDisabled = ref<boolean>(true);

    let uniqueKey = ref(0);
    let validation = ref<InstanceType<typeof Validation> | null>(null);
    
    const formSection = ref<IFormSection>(addFormStore.getAddFormModal.formSection);
    const customValidation = ref<IFormSection>(addFormStore.getAddFormModal.CustomValidation);
    const onItemAdded = ref<Function>((fields : z.infer<typeof FieldInfo>) => {});
    const onItemUpdate = ref<Function>((fields : z.infer<typeof FieldInfo>) => {});

    const isFormSectionDynamicEval = computed(() => {
        return () => {
            let isDynamic = false;

            if (typeof(formStore.SelectedFormSection) !== 'undefined' && formStore.SelectedFormSection != null) {
                isDynamic = formStore.SelectedFormSection!.Dynamic === true;
            }
            return isDynamic;
        }
    });

    const modalDescription = computed(() => {
        if (typeof(formStore.SelectedFormSection) !== 'undefined' && formStore.SelectedFormSection != null) {
            return (displayAddButton ? "Add " : "Update ") + formStore.SelectedFormSection.Description;
        }
    });

    function validateAllSections(showErrors: boolean = true) {
        emit('validateAllSections', showErrors)
    }

    async function formFieldValueChange(field: z.infer<typeof FieldInfoItem>) {
        try{
            formFields.value.filter(_ => _.sender === field.id).forEach(_ => {
                fieldVisibility(_, formFields.value, false);
            });

            let isDisabled = false;
            validation.value = dynamicWebFormValidation(formSection.value.Fields, formFields.value);
            validation.value.validate();
            isDisabled = !validation.value.isValid();


            if (isDisabled === false){
                let hasValue = false;
                for(let index in formFields.value){
                    let _field = formFields.value[index];
                    if (_field != null){
                        if (_field.isShown && _field.value !== null && _field.value !== undefined && _field.value !== ""){
                            hasValue = true;
                            break;
                        }
                    }
                }
                isDisabled = !hasValue;                
            }
            
            IsButtonDisabled.value = isDisabled;
            
        }
        catch (error) {
            //debugger ????
        }
    }

    watch(()=> addFormStore.getAddFormModal.isOpen, ()=> {
        if (addFormStore.getAddFormModal.isOpen){
            formSection.value = addFormStore.getAddFormModal.formSection;
            formFields.value = addFormStore.getAddFormModal.fieldInfo;
            customValidation.value = addFormStore.getAddFormModal.CustomValidation;
            onItemAdded.value = addFormStore.getAddFormModal.onItemAdded;
            onItemUpdate.value = addFormStore.getAddFormModal.onItemUpdate;

            if (formSection.value.Fields != null){
                IsButtonDisabled.value = true;
                
                if (addFormStore.getAddFormModal.isOpen === true) {
                    if (addFormStore.getAddFormModal.action === "new") {
                        
                        displayAddButton.value = true;
                    } 
                    else if (addFormStore.getAddFormModal.action === "edit") {
                        displayAddButton.value = false;
                    } 
                    else {
                        displayAddButton.value = true;
                    }
                } 
                else {
                    displayAddButton.value = false;
                }
            }
            uniqueKey.value = getUniqueKey(uniqueKey.value);
        }
    });

    // intialises
    uniqueKey.value = getUniqueKey(uniqueKey.value);


    const addObjectToFormButton: IControlButton = {
        buttonElName: "gibs-add-object-to-form-button",
        buttonText: "Add +",
        class: "gibs-add-object-to-form-button",
        enableUrl: false,
        externalSource: false,
        openNewTab: false,
        sourceUrl: "/",
        disabled: true
    }

    const updateObjectFormButton: IControlButton = {
        buttonElName: "gibs-update-object-form-button",
        buttonText: "Update",
        class: "gibs-update-object-form-button",
        enableUrl: false,
        externalSource: false,
        openNewTab: false,
        sourceUrl: "/",
        disabled: true
    }
    let dynamicItem: IQualificationListItem | IProfessionalMembershipsItem | IProfessionalWorkItem  | IAdditionalDelegateItem | null  = null;


    function addObjectToForm(){
        formStore.setSelectedFormSectionState(formSection.value, FormSectionStates.ValidationFailed);
        formStore.ModalCollection[addFormStore.getAddFormModal.collectionName].unshift(formFields.value);
        onItemAdded.value(formFields.value);
        addFormStore.closeAddFormModal();
        //uniqueKey.value = getUniqueKey(uniqueKey.value);
    }

    function updateObjectForm(){
        formFields.value.forEach(_ => {
            if (_.isExternalValidation === true){
                _.externalValidationState = ExternalValidationStates.None;
            }
        });
        onItemUpdate.value(formFields.value);
        addFormStore.closeAddFormModal();
    }
  </script>

<style scoped>
    input:checked ~ .dot {
        transform: translateX(100%);
    }

    input:checked ~ .block {
        background-color: #48bb78;
    }
</style>