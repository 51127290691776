<template v-if="layoutView.view !== 'mobile'">
	<div class="gibs-login-wrapper">
		<div class="gibs-login-containers">
			<div id="gibs-login-form" class="gibs-login-form">
				<h3 class="forgot-password-modal-title">Forgot your Password</h3>
				<hr class="gibs-divider mt-3.75">
				<p class="forgot-password-description">Reset your password by submitting your email address linked to your
					GIBS account. We will send you an email with a link to choose a new password.</p>
				<hr class="gibs-divider mb-5.5">
				<form @submit.prevent="submitForm"> 
					<GibsControlLoginFormInput type="email" class="w-100" labelText="Email address"
						:inputId="'forgot-form-email'" :placeholderText="'name\@email.com'" v-model="form.email"
						:validator-rule-id="app.$EnumForgotPasswordFormValidatorId.ForgotPasswordEmail" @input="emailInput"
						:validator="validation">
					</GibsControlLoginFormInput>
					<GibsControlPrimaryButton :primaryButtonSpec="forgotFormSubmitButton" /> 
				</form>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { useNotificationStore } from '~/stores/notification';


const store = useNotificationStore();
const app = useNuxtApp();
let showSpinner = ref<boolean>(false);

type ElementWithNewProperties<T> = Partial<T> & {
	checked?: boolean,
	value?: string
}

const props = defineProps(['userNameProps', 'componentId']);
const emit = defineEmits(['toggleResetEmailSentForm']); 
const form = ref<IForgotPassword>({
	email: ''
}); 

const { forgotPasswordFormValidation} = useValidations();
const validation = forgotPasswordFormValidation(form.value);

const forgotFormSubmitButton = ref<IControlButton>({
	buttonElName: `${props.componentId}-button`,
	buttonText: "Submit",
	class: "submit-contact-form",
	disabled: !validation.isValid(),
	enableUrl: false,
	type: "submit" as ButtonHTMLAttributes,
	load: false
});
forgotFormSubmitButton.value.disabled = !validation.isValid();

const submitForm = () => { 
	const formData: IForgotPassword = {
		email: form.value.email
	};
	sendDataToServer(formData);
};

function emailInput(input: string) {
	form.value.email = input;
	validation.setValidationRuleSourceValue(app.$EnumForgotPasswordFormValidatorId.ForgotPasswordEmail, input);
	forgotFormSubmitButton.value.disabled = !validation.isValid();
}

const sendDataToServer = async (formData: IForgotPassword) => {
	showSpinner.value = true;
	let notification: INotification;
	try {
		forgotFormSubmitButton.value.disabled = true;
		forgotFormSubmitButton.value.load = true;

		const { data: response, error, status } = await useLoaderFetch('/api/forgot-password/postFormData', {
			method: 'POST',
			body: { data: formData }
		});

		forgotFormSubmitButton.value.disabled = false;
		forgotFormSubmitButton.value.load = false;

		showSpinner.value = false;
		const isSuccess: string = 'success';
		const isError: string = 'error';
		
		if (status.value == isSuccess) { 
			emit('toggleResetEmailSentForm', true);
			const formElement = document.getElementById("gibs-home-contact-form")! as HTMLFormElement;
			formElement.reset();
			validation.setValidationRuleSourceValue(app.$EnumForgotPasswordFormValidatorId.ForgotPasswordEmail, form.value.email);
			notification = {
				type: "success",
				title: "Email sent.",
				body: `Password reset email successfully sent. Please check your mailbox.`,
				date: new Date(),
				timeout: true,
				timer: 5000
			};
		}
		// else if (status.value == isError) {
		else { 
			//emit('toggleResetEmailSentForm', false);
			notification = {
				type: "error",
				title: "",
				body: `There was an error while sending you the reset email. Please try again.`,
				date: new Date(),
				timeout: true,
				timer: 10000
			};
		}
		store.addNotification(notification);
	}
	catch (error) {
		showSpinner.value = false;
		if (error instanceof ServerValidationError) {
			notification = {
				type: "error",
				title: `There was an error while sending you the reset email. Please try again.`,
				body: error.message,
				date: new Date(),
				timeout: true,
				timer: 10000
			};
			store.addNotification(notification);
		}
		console.error('Error sending form data:', error);
	}
}; 
</script>