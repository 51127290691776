<template>
    <span v-if="isDebugMode()"> 
        {{ showSpinner }} - {{ fetchRequests }}
    </span>
    
    <div v-if="showSpinner" class="full-spinner-container">
        <span class="loader">
        </span>
    </div>
</template>

<script setup lang="ts">
interface IRequest {
    request: any;
    date: Date;
    status: string;
}
    import { isDebugMode } from '~/utils/display-utils';
    import { ref, defineExpose } from 'vue'
    import { useNotificationStore } from "~/stores/notification";

    const store = useNotificationStore();
    const showSpinner = ref<boolean>(false);
    const fetchRequests = ref<Array<IRequest>>(new Array<IRequest>());
    const waitTimeout = ref<NodeJS.Timeout>();
    const rejectMethod = ref<Function>();
    const currentTimeout = ref<Number>(240000); // TODO: Change back to 60000 when Seacom internet cable issue sorted

    watchEffect(() => {
        if (showSpinner.value == false){
            if (fetchRequests.value.length > 0){
                showSpinner.value = true;
            }
        }

        if(process.client) 
        {
            if (showSpinner.value == true)
            {
                if (fetchRequests.value.length == 0)
                {
                    console.log(`************* Begin Loader info ${showSpinner.value} && ${fetchRequests.value.length} busy Fetching *************`);
                    console.log(JSON.stringify(fetchRequests.value));
                    console.log(`************* End Loader info ${showSpinner.value} && ${fetchRequests.value.length} busy Fetching *************`);
                }
            }
        }
    });

    defineExpose({LoadData, showSpinnerFor, IsBusyLoading, hideSpinner, setFetchData, removeFetchData, resetTimeout})
    const emit = defineEmits(["LoadingStateChange"]);

    function resetTimeout(){
        if (waitTimeout.value !== null){
            window.clearTimeout(waitTimeout.value);
            waitTimeout.value = null;
            setLoaderTimeout(currentTimeout.value);
        }
    }


    function setLoaderTimeout(ms: number = 240000) { // TODO: Change back to 60000 when Seacom internet cable issue sorted
        if (rejectMethod.value == null){
            rejectMethod.value = (e) => {
                console.log(e);
                //debugger;
            }
        }
        waitTimeout.value = setTimeout(() => rejectMethod.value(new Error('timeout succeeded')), ms);
    }

    function setFetchData(request:IRequest) : boolean {
        if (fetchRequests.value.find(_ => _.request == request.request) == null)
        {
            fetchRequests.value.push(request);
            console.log(`*************  FetchData [${request}] *************`);
            if (waitTimeout.value !== null){
                window.clearTimeout(waitTimeout.value);
                waitTimeout.value = null;
                setLoaderTimeout(currentTimeout.value);
            }
            return true;
        }
        else{
            //debugger;
            console.log(`*************  FetchData [${request}] already exists *************`);
            return false;
        }
    }

    function removeFetchData(request:IRequest){
        if (fetchRequests.value.length > 0){

            fetchRequests.value = fetchRequests.value.filter(x => 
            {
                let isSame = (x.request === request.request);
                return !isSame;
            });

            if (fetchRequests.value.length == 0){
                window.clearTimeout(waitTimeout.value);
                waitTimeout.value = null;
                hideSpinner();
            }

        }
    }

    function wait(ms: number = 240000) { // TODO: Change back to 60000 when Seacom internet cable issue sorted
        return new Promise(async (_, reject) => {
            rejectMethod.value = reject;
            setLoaderTimeout(ms);
        });
    }

    async function showSpinnerFor(timeout: number = 240000) { // TODO: Change back to 60000 when Seacom internet cable issue sorted
        return new Promise(async (_) =>{
            showSpinner.value = true;
            scrollToTop();
            await new Promise(f => setTimeout(f, timeout));
            showSpinner.value = false;
        });
    }

    async function hideSpinner() {
        showSpinner.value = false;
    }

    function IsBusyLoading() : boolean {
        return showSpinner.value;
    }

    function hashCode(str: string): number {
        var h: number = 0;
        for (var i = 0; i < str.length; i++) {
            h = (h + str.charCodeAt(i));
        }
        return h & 0xFFFFFFFF
    }

    async function LoadData(executeLogic : (request:any) => any, timeout: number = 240000) : Promise<Boolean|Error> // TODO: Change back to 60000 when Seacom internet cable issue sorted
    {
        currentTimeout.value = timeout;
        showSpinnerFor(timeout);
        // This is actually needed to prevent timing issues loading the forms, reduced to 100ms
        // Without this delay, the form loading order fires in the incorrect order
        await new Promise(f => setTimeout(f, 100));

        let doExecute = new Promise(async (resolve, reject) => {
            let functionHash = hashCode(executeLogic.toString());

            let request = {request: `executeLogic-${functionHash}`, date: new Date(), status: 'Init'};
            try {
                // await new Promise(f => setTimeout(f, 10000));
                if (setFetchData(request) == true)
                {
                    let result = await executeLogic(request);
                    removeFetchData(request);
                    resolve(result);
                }
                else{
                    //debugger;
                }
            }
            catch (ex) {
                removeFetchData(request);
                //debugger;
                reject(ex);
            }
        });

        return Promise.race([wait(timeout), doExecute])
            .then(() => {
                showSpinner.value = false;
                
                return true;
            })
            .catch((ex: Error) => {
                if (fetchRequests.value.length == 0){

                    console.error(ex);
                    showSpinner.value = false;

                    // HACK Check Default.vue this is where this is causing an Error. (TODO : 20240311) 

                    if (ex.message?.indexOf('/api/auth/login') > 0){
                        return false;
                    }
                    else{
                        // debugger;
                        // store.addNotification({
                        //     type: "error",
                        //     title: `Timeout`,
                        //     body: 'There was an error while processing your request. Please try again later. If the issue persists, please contact support.',
                        //     date: new Date(),
                        //     timeout: false
                        // });
                        return false;
                    }
                }
                return true;
                //throw ex;
            });
    }

    function scrollToTop() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    function showScroller(showSpinner: boolean) {
        if(process.client) {
            let elements = document.getElementsByTagName('body');
                elements.forEach((element: HTMLElement) => {
                    if(showSpinner) {
                        element.classList.add('spinner-scrollbar-hide');
                    }
                    else {
                        element.classList.remove('spinner-scrollbar-hide');
                    }
            });
        }
    }

    watch(() => showSpinner.value, (newVal, oldValue) => {
        showScroller(showSpinner.value)
        emit("LoadingStateChange",newVal);
    });
</script>