import { z } from 'zod';
// Note this is the schema from azure functions api
export const EnrolmentQuery = z.object({
    title: z.string().optional(),
    enrolmentDate: z.string().nullable().optional(),
    enrolmentStatus: z.string().optional(),
    applicationClosingDate: z.string().nullable().optional(),
    programmeStartDate: z.string().nullable().optional(),
    programmeCode: z.string().nullable().optional(),
    programmeID: z.string().optional(),
    isProgrammeLevel3Or4: z.boolean().optional(),
    enrolmentId: z.string().optional(),
    isEnrolmentOnStartProgress: z.boolean().optional(),
    enrolmentStatusLevel: z.number().optional(),
    enrolmentStatusClassification: z.string().nullable().optional(),
    isProgrammeClosed: z.boolean().optional(),
    isAborted: z.boolean().optional()
});

export const EnrolmentList = z.array(EnrolmentQuery);

export const MyLearningsQuery = z.object({
    contactID: z.string().optional(),
    errorMessage: z.string().optional(),
    success: z.boolean().optional(),
    currentEnrolments: EnrolmentList.optional(),
    inProgressEnrolments: EnrolmentList.optional(),
    completedEnrolments: EnrolmentList.optional(),
});